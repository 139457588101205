import store, {dispatch} from '@store';
import axiosInstance, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  loginActions,
  loginReducerLogout,
} from '../../pages/Login/auth/store/loginReducer';
import {BASE_URL} from '../helpers/constants';
import {API_CONSTANTS, coreActions} from '../store/loadingReducer';
import {alertActions} from './store/alertReducer';

const axios = axiosInstance.create({
  baseURL: BASE_URL,
});

export type API_KEYS = keyof typeof API_CONSTANTS;

export interface ICustomError extends AxiosError {
  'error-code': string;
  'error-message': string;
}

export interface AxiosRequestConfigWithParams extends AxiosRequestConfig {
  API?: API_KEYS;
}

const handleTopLoader = (isLoading: boolean) => {
  dispatch(coreActions.handleIsLoading(isLoading));
};

const getErrorMessage = (errorObj: AxiosError<unknown, string>) => {
  let errorCode = '';
  let errorMessage = '';
  if (errorObj.response?.data) {
    const responseData = errorObj.response.data as ICustomError;
    errorCode = responseData['error-code'];
    errorMessage = responseData['error-message'];
  }
  return {errorCode, errorMessage};
};

let isRefreshing = false;
let failedQueue: unknown[] = [];

const processQueue = (error: unknown, token = null) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

function throwError(e: {response: unknown}) {
  throw e.response;
}

function getInstance(url: string) {
  const axiosInstanceForRequest = axiosInstance.create({
    baseURL: BASE_URL,
  });

  // Adding interceptor here because we have 2 URLs
  axiosInstanceForRequest?.interceptors.request.use(
    (config: AxiosRequestConfigWithParams) => {
      const {sessionToken, seedflexMId, seedflexPid, countryCode} =
        store.getState().login;

      if (config.headers && countryCode) {
        config.headers.countryCode = countryCode;
      }
      if (config.headers && sessionToken) {
        config.headers.authorization = sessionToken;
        if (seedflexPid) {
          config.headers.seedflexmid = seedflexMId;
          config.headers.prospectId = seedflexPid;
        } else {
          config.headers.seedflexmid = seedflexMId;
        }
      }
      handleTopLoader(true);
      return config;
    },
    (error: AxiosError) => {
      const {errorCode, errorMessage} = getErrorMessage(error);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || error.code,
          message: errorMessage || error.message,
          alertType: 'danger',
        })
      );
      handleTopLoader(false);
      return Promise.reject(error);
    }
  );

  axiosInstanceForRequest?.interceptors.response.use(
    (response: AxiosResponse) => {
      handleTopLoader(false);
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const {errorCode, errorMessage} = getErrorMessage(error);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || error.code,
          message: errorMessage || error.message,
          alertType: 'danger',
        })
      );
      // if (originalRequest.url === '') {
      //   handleTopLoader(false);
      //   return Promise.reject(error);
      // }
      if (error?.response?.status === 401) {
        handleTopLoader(false);
        // dispatch(loginActions.logout());
        dispatch(loginReducerLogout());
        dispatch(loginActions.setIsShowOtp(false));
        dispatch(loginActions.setIsSelectBRN(false));
        return Promise.reject(error);
      }
      if (error.response.data.message.includes('not found')) {
        dispatch(loginActions.setNotFoundData(true));
      }
      // eslint-disable-next-line no-underscore-dangle
      if (error?.response?.status === 401 && !originalRequest._retry) {
        if (originalRequest.url === '/auth/refresh') {
          processQueue(error, null);
          handleTopLoader(false);
          // type here should be session expired
          return Promise.reject(error);
        }
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({resolve, reject});
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              handleTopLoader(false);
              return axiosInstanceForRequest(originalRequest);
            })
            .catch((isRefreshingError) => {
              handleTopLoader(false);
              return Promise.reject(isRefreshingError);
            });
        }

        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`/auth/refresh`)
            .then(({data}: AxiosResponse) => {
              // set access token from response
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
              originalRequest.headers.Authorization = `Bearer ${data.data.token}`;
              dispatch(loginActions.setSessionToken(data.data.token));
              processQueue(null, data.data.token);
              handleTopLoader(false);
              resolve(axiosInstance(originalRequest));
            })
            .catch((refreshErr: Error | AxiosResponse) => {
              processQueue(refreshErr, null);

              handleTopLoader(false);
              // dispatch session expired
              // dispatch({
              //   type: AUTH_ACTIONS.SESSION_EXPIRED,
              // });
              // dispatch({
              //   type: CORE_ACTIONS.ENABLE_MODAL,
              //   payload: {
              //     message: 'Please login again',
              //     severity: 'error',
              //     alertTitle: 'Session expired'
              //   }
              // });
              // dispatch(authActions.logout());
              reject(refreshErr);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      handleTopLoader(false);
      if (error.response.data) {
        // dispatch store for enabling modal
      }
      return Promise.reject(error);
    }
  );

  return axiosInstanceForRequest;
}

export async function get(url: string, data?: object) {
  try {
    const resp = await getInstance(url).get(url, data);
    dispatch(loginActions.setNotFoundData(false));
    return resp.data;
  } catch (e) {
    return throwError(e as {response: unknown});
  }
}

export async function post(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).post(url, data, config);
    dispatch(loginActions.setNotFoundData(false));
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      if (url.includes('/auth/select-bin')) {
        // dispatch(loginActions.logout());
        dispatch(loginReducerLogout());
        dispatch(loginActions.setIsShowOtp(false));
        dispatch(loginActions.setIsSelectBRN(false));
      }
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

// keep below underscore-dangle off for only this function as the name
// overlaps with the delete keyword in javascript
// eslint-disable-next-line no-underscore-dangle
export async function _delete(url: string, data?: unknown): Promise<unknown> {
  try {
    const res = await getInstance(url).delete(
      url,
      data as AxiosRequestConfig<unknown> | undefined
    );
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export async function put(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).put(url, data, config);
    dispatch(loginActions.setNotFoundData(false));
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export async function patch(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig | undefined
): Promise<unknown> {
  try {
    const res = await getInstance(url).patch(url, data, config);
    dispatch(loginActions.setNotFoundData(false));
    return res.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      const {errorCode, errorMessage} = getErrorMessage(e);
      dispatch(
        alertActions.setAlertMsg({
          code: errorCode || e.code,
          message: errorMessage || e.message,
          alertType: 'danger',
        })
      );
    }
    return throwError(e as {response: unknown});
  }
}

export default axios;
